import { WagmiConfig } from 'wagmi'

import {useEffect} from 'react'
import { Buffer } from "buffer";
import React from 'react';

import FrontPage from './components/FrontPage'
import AccountPage from './components/AccountPage'
import {wagmiConfig} from './components/Config'

import './App.css';

import GlobalStyle from './style/GlobalStyle'

import {
  createBrowserRouter,
  RouterProvider,
  useParams 
} from "react-router-dom";

window.Buffer = window.Buffer || Buffer;
 
const router = createBrowserRouter([
  {
    path: "/",
    element: <FrontPage/>,
  },

  {
    path: "/account",
    element: <AccountPage/>,
  }

  /*{
    path: "*",
    element: <ErrorPage />,
  },*/
]);


function App() {
  return (

    <WagmiConfig config={wagmiConfig}>
      <GlobalStyle />
      <RouterProvider router={router} />
    </WagmiConfig>
  );
}

export default App;
