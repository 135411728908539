import { mainnet, goerli, sepolia } from 'wagmi/chains'
import { Chain } from 'wagmi'
import { createConfig, configureChains  } from 'wagmi'

import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'
import { InjectedConnector } from 'wagmi/connectors/injected'
import {WalletConnectConnector} from 'wagmi/connectors/walletConnect'
//import { coinbaseWallet, injected, walletConnect, metaMask, safe   } from 'wagmi/connectors'

export function abbreviateAddress(address: string, charsLength: number = 4): string {
  if (typeof address !== 'string') return '';
  if (address.length < charsLength * 2 + 2) return address;

  const prefix = address.substr(0, charsLength + 2); // '0x' + charsLength characters
  const suffix = address.substr(-charsLength);

  return `${prefix}...${suffix}`;
}

export const unichain = {
  id: 130, // TODO: FIX
  name: 'Unichain',
  network: 'unichain',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  rpcUrls: {
    //public: { http: ['https://mainnet.unichain.org/'] },
    public: { http: ['https://unichainrpc.chaineye.tools/'] },
    default: { http: ['https://unichainrpc.chaineye.tools/'] },
    
  },
  blockExplorers: {
    etherscan: { name: 'Unichain Testnet Explorer', url: 'https://uniscan.xyz' },
    default: { name: 'Unichain Testnet Explorer', url: 'https://uniscan.xyz' },
  }
} 

export const unichainsepolia = {
  id: 1301,
  name: 'Unichain Sepolia',
  network: 'unichainsepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  rpcUrls: {
    //public: { http: ['https://sepolia.unichain.org'] },
    //default: { http: ['https://sepolia.unichain.org'] },

    public: { http: ['	https://sepolia.unichain.org'] },
    default: { http: ['https://unichain-sepolia.g.alchemy.com/v2/k5u1H1zQC3iHJZJGTUzZeUpw11HbzT0L'] },
    
  },
  blockExplorers: {
    etherscan: { name: 'Unichain Testnet Explorer', url: 'https://sepolia.uniscan.xyz' },
    default: { name: 'Unichain Testnet Explorer', url: 'https://sepolia.uniscan.xyz' },
  }
} 

const getEtherScan = () => {
  switch (chain.network) {
    case "blast":  return "https://blastscan.io";
    case "blastsepolia":  return "https://testnet.blastscan.io";
    //case "unichain":  return "https://uniscan.xyz";
    case "unichain":  return "https://unichain.blockscout.com";
    case "unichainsepolia":  return "https://sepolia.uniscan.xyz";
    case "sepolia":  return "https://sepolia.etherscan.io";
    case "goerli":  return "https://goerli.etherscan.io";
  }
}
//export const chain = unichain; // <-- MAINNET SET LIVE
export const chain = unichain; // testnet

export const getEtherscanAddressLink = (hash) => {
  return `${getEtherScan()}/address/${hash}`;
}

export const getEtherscanTxnLink = (hash) => {
  return `${getEtherScan()}/tx/${hash}`;
}

export const getEtherscanContractLink = (hash) => {
  return `${getEtherScan()}/address/${hash}`;
}

export const links = {
  twitter: "https://twitter.com/dailofrog",
  twitterGoop: "https://twitter.com/goopgoop_art",
  linktree: "https://linktr.ee/dailofrog",
  discord: "https://discord.gg/KBAbk2a4PM",
  contractFrontEnd: getEtherscanContractLink(process.env.REACT_APP_CONTRACT_DN404),
  contractMirror: getEtherscanContractLink(process.env.REACT_APP_CONTRACT_DNMIRROR),
  opensea: "https://opensea.io/collection/blastrocks/",
  unichain: "https://www.unichain.org/",
  dn404: "https://x.com/0xCygaar/status/1757136657286119916",
  uniswapLP: "https://www.unichain.org/",
};

export const data = {
  hidden: true, 

  // alloc
  allocDev: 5,
  allocPublic: 85,
  allocLP: 10,

  totalSupply: 5000,
  pricePublic: "0.006",
  priceWhitelist: "0.004",
  
  ticker: "$TINY",

  numVariations: 3440640,
}


const { chains, publicClient, webSocketPublicClient  } = configureChains(
  [chain],
  [
    //alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY }),
    //publicProvider(),

    jsonRpcProvider({
      rpc: (chain) => ({
        http: chain.rpcUrls.public.http[0],
        //http: "https://rpc.ankr.com/blast_testnet_sepolia/13bc3e5c2b4ca20f87bf24426fde9530951c2ee4b658c542f919396d3ffb90f1",
      }),
    }),

  ],
)

export const wagmiConfig = createConfig({
  autoConnect: true,
  publicClient,
  connectors: [
    new InjectedConnector({ chains }),
    new WalletConnectConnector({chains, 
      options: { projectId: "577741f86c194e5900ea77f7693f805d", metadata:{name:"tinyass unicorns", url:"https://tinyunicorns.xyz"}}
    }),
    //metaMask(),
    //coinbaseWallet({ appName: 'tinyass unicorns', preference: 'smartWalletOnly',headlessMode: false,   }),
    //walletConnect({ projectId: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID, metadata:{name:"HYPERLANDS", url:"https://hyperlands.art"} }),
    //safe()
  ],
  ssr: true,
  webSocketPublicClient
});

if (!data.hidden) {
  console.log(`Chain: ${chain.name} (${chain.id})`)
  console.log(`TinyUnicorns404.sol: ${getEtherscanContractLink(process.env.REACT_APP_CONTRACT_DN404)}`)
  console.log(`Mirror.sol: ${getEtherscanContractLink(process.env.REACT_APP_CONTRACT_DNMIRROR)}`)
}