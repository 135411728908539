import * as Style from "../style/StyledComponents";
import { abbreviateAddress, chain, getEtherscanAddressLink, getEtherscanTxnLink, getEtherscanContractLink, links } from "./Config";
import {useState, useEffect} from 'react'

import { InjectedConnector } from 'wagmi/connectors/injected'
import { useConnect , useAccount, useDisconnect } from 'wagmi'
import { usePrepareContractWrite, useContractWrite , useContractReads  } from 'wagmi'
import { waitForTransaction, fetchBalance  } from '@wagmi/core'

import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import ConnectWalletButton from "./ConnectWalletButton";

import { getMerkleRoot, containsAddressInList, getMerkleProof } from "../merkle/merkle";
import { merkleRoot as merkleRootPrecalc } from "../merkle/addresses";
import imgEgg from "../images/egg.png";

BigNumber.config({ EXPONENTIAL_AT: 50 })

const contractAbi = require("../abi/TinyUnicorn404.json").abi;

const frontendContract = {
  address: process.env.REACT_APP_CONTRACT_DN404,
  abi: contractAbi,
}


const MINT_STATUS = {
  Disabled:0,
  Allowlist:1,
  Public:2
}

const MINT_STATUS_NAMES = [
  <span>Paused</span>,
  <span>📋 Whitelist Only</span>,
  <span>Public</span>,
];

function Egg() {
  return (
      <img style={{width:"25px", userSelect:"none"}} draggable="false" src={imgEgg} />
  )
}

export const Mint = () => {

  const { isConnected , address } = useAccount()
  const { disconnect } = useDisconnect()
  const { connectors, connect } = useConnect({
    connector: new InjectedConnector({chains:[chain]}),
    chainId: chain.id,
  });

  // from contract
  const [currentSupply, setCurrentSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(1000);
  const [publicMintPrice, setPublicMintPrice] = useState("0.005");
  const [allowlistMintPrice, setAllowlistMintPrice] = useState("0.0035");
  const [mintStatus, setMintStatus] = useState(0);
  const [maxPerWalletPublic, setMaxPerWalletPublic] = useState(0);
  const [maxPerWalletAllowlist, setMaxPerWalletAllowlist] = useState(0);
  const [numMintedByAddress, setNumMintedByAddress] = useState(0);
  const [merkleRoot, setMerkleRoot] = useState("");
  
  const [mintCount, setMintCount] = useState(1);
  const [maxMintAmount, setMaxMintAmount] = useState(1);
  const [mintPrice, setMintPrice] = useState("0.005");
  const [walletBalance, setWalletBalance] = useState("0.0");
  const [merkleRootLocal, setMerkleRootLocal] = useState("");

  const addressIsWhitelisted = containsAddressInList(address);

  // calculated
  const [totalMintPrice, setTotalMintPrice] = useState("0.0");

  const { data:readData, isError:readError, isLoading:readIsLoading } = useContractReads({
    enabled:true,
    contracts: [
      {
        ...frontendContract,
        functionName: 'totalMinted',
      },
      {
        ...frontendContract,
        functionName: 'MAX_SUPPLY',
      },
      {
        ...frontendContract,
        functionName: 'maxPerWalletPublic',
      },
      {
        ...frontendContract,
        functionName: 'maxPerWalletAllowlist',
      },
      {
        ...frontendContract,
        functionName: 'mintStatus',
      },
      {
        ...frontendContract,
        functionName: 'publicPrice',
      },
      {
        ...frontendContract,
        functionName: 'allowlistPrice',
      },
      {
        ...frontendContract,
        functionName: 'merkleRoot',
      },
      {
        ...frontendContract,
        functionName: 'getNumMinted',
        args: [address],
      },
    ],
  });

  async function checkBalance() {
     const balance = await fetchBalance({
        address: address,
      });

      const price = new BigNumber(parseInt(balance.value));
      const total = price.multipliedBy("1E-18");
      setWalletBalance(total.toString());

      console.log(balance);
  }

  useEffect(()=>{

    if (isConnected && address) {
      checkBalance();
    }
  }, [address,isConnected]);

  useEffect(()=>{
    if (readData != null) {
      console.log("Contract read:", readData);

      const _maxPerWalletPublic = parseInt(readData[2].result);
      const _maxPerWalletAllowlist = parseInt(readData[3].result);
      const _numMintedByAddress = parseInt(readData[8].result);
      const _mintStatus = parseInt(readData[4].result);

      setCurrentSupply( parseInt(readData[0].result));
      setMaxSupply( parseInt(readData[1].result));
      setMaxPerWalletPublic(_maxPerWalletPublic);
      setMaxPerWalletAllowlist(_maxPerWalletAllowlist);
      setMintStatus(_mintStatus);
      setNumMintedByAddress(_numMintedByAddress);
      setMerkleRoot(readData[7].result);

      // public price
      const pricePublic = new BigNumber(parseInt(readData[5].result));
      const totalPublic = pricePublic.multipliedBy("1E-18");
      setPublicMintPrice(totalPublic.toString());

      // allowlist price
      const priceAllowlist = new BigNumber(parseInt(readData[6].result));
      const totalAllowlist = priceAllowlist.multipliedBy("1E-18");
      setAllowlistMintPrice(totalAllowlist.toString());

      // max price
      const currentMaxSupply = addressIsWhitelisted ? _maxPerWalletAllowlist : _maxPerWalletPublic;
      setMaxMintAmount( Math.max(currentMaxSupply-_numMintedByAddress, 0));
      setMintCount(Math.min(1, currentMaxSupply));

      // mint price
      setMintPrice( addressIsWhitelisted ? totalAllowlist.toString() : totalPublic.toString());
    }

  }, readData);

  const { data, isLoading, isSuccess, error, isError, write, reset } = useContractWrite({
    address: process.env.REACT_APP_CONTRACT_DN404,
    abi: contractAbi,
    functionName: addressIsWhitelisted ? 'allowlistMint':'mint',
    chainId: chain.id,
    account: address,

    onSettled(data, error) {
      console.log('Transaction Settled', { data, error })
    },
  });

  useEffect(()=>{
    const price = new BigNumber(mintPrice);
    const total = price.multipliedBy(mintCount);
    setTotalMintPrice(total.toString());
  },  [mintPrice, mintCount]);

  useEffect(()=>{
    const _merkleLocal = merkleRootPrecalc;//getMerkleRoot();
    setMerkleRootLocal(_merkleLocal);
    console.log(`Local merkle root: ${_merkleLocal}`);
  },  []);


  function onClickPlus() {
    setMintCount(Math.min(maxMintAmount, mintCount+1));
  }

  function onClickMinus() {
    setMintCount(Math.max(1, mintCount-1));
  }

  function onClickConnect() {
    console.log("CONNECT");
    connect();
  }

  function onClickDisconnect() {
    console.log("Disconnect");
    disconnect();
    reset();
  }

  async function onClickPublicMint() {
    reset();

    const args = addressIsWhitelisted ? [mintCount, getMerkleProof(address)] : [mintCount];
    console.log(`${addressIsWhitelisted? "allowListMint()": "mint()" } with numMints: ${mintCount}, payable: ${totalMintPrice} ETH, args:[${args}]`);

    write({
          args: args,
          from: address,
          value: ethers.parseEther(totalMintPrice),
    });
  }

  return (
    <div>

{/*
    {<>
        <Style.Button disabled>minted out!</Style.Button>
        <p style={{fontSize:"0.75em", marginTop:"5px"}}>✔️ 5000/5000 ( <a href={links.opensea} target="_blank">view collection</a> )</p>
      </>}
*/}

    {!isConnected && <><ConnectWalletButton>connect</ConnectWalletButton></>}


    {isConnected && mintStatus==MINT_STATUS.Disabled && <Style.Button disabled={true}>minting not available</Style.Button>}
    {isConnected && mintStatus!=MINT_STATUS.Disabled && currentSupply == maxSupply && <Style.Button disabled={true}>✔️ minted out!</Style.Button>}
    {isConnected && mintStatus==MINT_STATUS.Allowlist && currentSupply < maxSupply && !addressIsWhitelisted && <Style.Button disabled={true}>currently WL only (sorry)</Style.Button>}

    {isConnected && (mintStatus==MINT_STATUS.Public || (mintStatus==MINT_STATUS.Allowlist && addressIsWhitelisted)) && currentSupply < maxSupply && 
      <div style={{textAlign:"left"}}>
      <div style={{display:"flex", flexDirection:"row"}}>
        <Style.Button disabled={isLoading} hidden={mintCount<=1} onClick={onClickMinus}>-</Style.Button>
        <Style.Button disabled={isLoading || mintCount == 0} onClick={onClickPublicMint}>mint {mintCount} 🦄{addressIsWhitelisted && " (WL)"}</Style.Button>
        <Style.Button disabled={isLoading} hidden={mintCount>=maxMintAmount || (currentSupply+mintCount+1 > maxSupply) } onClick={onClickPlus}>+</Style.Button>
        </div>

      { mintCount > 0 && 
        <div style={{marginTop:"7px"}}>{Array.from({ length: mintCount }, (_, index) => <Egg key={index} />)}</div>
      }
         <div>
        <div style={{fontSize:"0.75em", color: "var(--gray-600)", marginTop:"0"}}>{mintCount} (of {maxMintAmount}) 🦄 x {mintPrice} Ξ each</div>
        <div style={{fontSize:"1em", marginTop:"5px",fontFamily:"Header", fontWeight:"bold", color: "var(--text)"}}>✔️ Total: <span style={{fontSize:"1.3em", color: "var(--text)", fontWeight:"bold"}}>{totalMintPrice} Ξ</span></div>
    </div>

    </div>
      }


    {isConnected && <div style={{marginTop:"1.5em", fontSize:"0.75em",textAlign:"left",}}>🟢 <strong>Connected</strong>: <a href={getEtherscanAddressLink(address)} target="_blank">{abbreviateAddress(address)}</a> (<a onClick={onClickDisconnect}>Disconnect</a>)</div>}

    {isConnected && <div style={{fontSize:"0.75em",textAlign:"left",}}>
      <p>{addressIsWhitelisted && <strong>🤙 Ayy, you're whitelisted!</strong>}</p>
      <p>&nbsp;&nbsp;Your Balance: <strong>{parseFloat(walletBalance).toPrecision(5)} Ξ</strong> ({chain.name})</p>
    </div>}

    {isConnected && 
      <div style={{marginTop:"1.2em", fontSize:"0.75em", textAlign:"left"}}>
        ▪ Mint Status: <strong>{MINT_STATUS_NAMES[mintStatus]}</strong>
        <br/>
        ▪ Total Minted: {currentSupply==maxSupply?"✔️ ":""}<strong>{currentSupply} / {maxSupply}</strong>
        <br/>
        ▪ {addressIsWhitelisted && 'Whitelist'} Mint Price (each): <strong>{mintPrice} Ξ</strong>
        <br/>
        ▪ Num Minted by You: <strong>{numMintedByAddress} 🦄</strong> (remaining: <strong>{maxMintAmount} 🦄</strong>)
        {/*
        <br/>
        ▪ Max Per Wallet: <strong>{maxPerWalletPublic}</strong> ({maxPerWalletAllowlist} for WL)
        */}
        <br/>
        ▪ Contracts: <a href={links.contractFrontEnd} target="_blank">{abbreviateAddress(process.env.REACT_APP_CONTRACT_DN404,4)}</a>
        <br/>
        ▪ Merkle Root: <strong>{abbreviateAddress(merkleRoot,3)}</strong> (local: {abbreviateAddress(merkleRootLocal, 3)})
      </div>
    }


    {isError && <Style.ErrorMessage>Error: {error.message}</Style.ErrorMessage>}

    {isSuccess && data != null && <Style.SuccessMessage><strong>✔️ Transaction Sent</strong>: <a href={getEtherscanTxnLink(data.hash)} target="_blank">{abbreviateAddress(data.hash, 8)}</a></Style.SuccessMessage>}

    </div>
  )
}
