import * as Style from "../style/StyledComponents";
import { Outlet, Link } from "react-router-dom";
import { links } from "./Config";


function Navbar() {
  return (
      //<GardenView tokenId={3}/>
      <>
            <Style.Navbar>
              <Style.HiddenMobile><h1> <Link to={`/`}>Modular Punks</Link></h1></Style.HiddenMobile>

               <Style.NavbarLinks>
               <Link to={`/account/`}>Account</Link>
              {/*<a rel="noreferrer" target="_blank" href="#">OpenSea</a>*/}
              <a rel="noreferrer" target="_blank" href={links.twitter}>Twitter</a>

         </Style.NavbarLinks>

            </Style.Navbar>
      </>
  );
}

export default Navbar;
