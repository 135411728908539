'use client'

// https://www.npmjs.com/package/react-modal
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        textAlign:"center",
        border:"none",
        boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
    },

    overlay: {
        backgroundColor: 'rgba(40, 40, 40, 0.9)',
    }
  };

Modal.setAppElement('#root');
  
export default function({ isOpen, onRequestClose, children }) {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Hyperlands Modal"
            style={customStyles}
        >
            {children}
        </Modal>
    )
}