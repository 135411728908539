import * as Style from "../style/StyledComponents";
import Navbar from "./Navbar";
import {Mint} from "./Mint";
import { links } from "./Config";


function SectionHero() {
  return (
         <Style.Section bg="var(--camo-700)">
          <Style.FixedHeightContainer style={{ minHeight: "17em" }}>  

            <h1>( Account Access Soon )</h1>
          </Style.FixedHeightContainer>
       
         </Style.Section>)
}

function AccountPage() {
  return (
      <>
        <Navbar/>
        <SectionHero/>

      </>
  );
}

export default AccountPage;
