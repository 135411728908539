import { MerkleTree } from 'merkletreejs'
import keccak256 from 'keccak256'

import {cleanedAddresses} from './addresses'


export function getMerkleRoot() {
    const resultMerkleTree = new MerkleTree(cleanedAddresses, keccak256, {
        hashLeaves: true,
        sortPairs: true,
    });

    const resultMerkleRoot = resultMerkleTree.getHexRoot();
    return resultMerkleRoot;
}

export function getMerkleLeafHex(address) {
    return "0x" + keccak256(address).toString('hex');
}

export function getMerkleLeaf(address) {
    return keccak256(address);
}

export function getMerkleProof(address) {
    const merkleTree = new MerkleTree(cleanedAddresses, keccak256, {
        hashLeaves: true,
        sortPairs: true,
    });

    const leaf = getMerkleLeaf(address);
    const resultMerkleProof = merkleTree.getHexProof(leaf);
    return resultMerkleProof;
}

export function getMerkleCheckAddress(address) {
    const merkleTree = new MerkleTree(cleanedAddresses, keccak256, {
        hashLeaves: true,
        sortPairs: true,
    });

    const resultMerkleRoot = merkleTree.getHexRoot();

    const leaf = getMerkleLeaf(address);
    const resultMerkleProof = merkleTree.getHexProof(leaf);
    const verifyResult = merkleTree.verify(resultMerkleProof, leaf, resultMerkleRoot);
    return verifyResult;
}

export function containsAddressInList(address) {
    if (address == null)
        return false;
    
    const addressLowerCase = address.toLowerCase();
    return cleanedAddresses.includes(addressLowerCase);
}