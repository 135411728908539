// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Faustina-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Lexend/LexendDeca-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Paragraph";   /*Can be any text*/
  src: local("Paragraph"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

@font-face {
  font-family: "Header";   /*Can be any text*/
  src: local("Header"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB,IAAI,kBAAkB;EAC9C;8DACkE;AACpE;;AAEA;EACE,qBAAqB,IAAI,kBAAkB;EAC3C;8DAC8D;AAChE","sourcesContent":["@font-face {\n  font-family: \"Paragraph\";   /*Can be any text*/\n  src: local(\"Paragraph\"),\n    url(\"./fonts/Faustina-VariableFont_wght.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Header\";   /*Can be any text*/\n  src: local(\"Header\"),\n    url(\"./fonts/Lexend/LexendDeca-Bold.ttf\") format(\"truetype\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
