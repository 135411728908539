import * as Style from "../style/StyledComponents";
import Navbar from "./Navbar";
import {Mint} from "./Mint";
import { abbreviateAddress, chain, links, data, getEtherscanTxnLink } from "./Config";

import { FaTwitter, FaDiscord, FaFilePdf, FaGlobe  } from "react-icons/fa";
import styled, { keyframes }  from 'styled-components';
import { useState } from "react";
import { useAccount, useDisconnect } from 'wagmi'

import imgHero from "../images/uni.png";
import imgSingle from "../images/smoker.png";
import imgChart from "../images/chart.png";

const FolderHeader = styled.nav`
	margin-top: 0.5em;
	padding-bottom: 0.25em;
	text-align: center;
`;

const FolderContent = styled.nav`
	padding: 0.5em 1em 2em 1em;
	font-size: 0.75em;
	text-align: left;
`;

const FolderTab = styled.span`
	font-family: "Header";
	font-weight: bold;
	
	border-top: 1px solid var(--text);
	border-left: 1px solid var(--text);
	border-right: 3px solid var(--text);
	border-radius: 7px 7px 0 0;

	padding:0.25em 1em;

	box-sizing: border-box;
	position: relative;
	top: 1px;
	margin: 0 0.4em;

	color: var(--link);
	cursor: pointer;
	user-select: none;

	&:hover {
		background-color: white;
	}

	${props => props.active == "true" && `
		border-bottom: 1px solid var(--background);
		background-color: var(--background);
		color: var(--text);
		cursor: default;
		user-select: none;

		&:hover {
			background-color: var(--background);
		}
	`}
`;

function SectionCollection() {
	return (
		<>
		<p><strong><a href="/tiny-unicorn-whitepaper.pdf" target="_blank">View Whitepaper (PDF) <FaFilePdf style={{position:"relative", top:"2px"}}/></a></strong></p>
		<br/>
		<p>
			{/*▪ Release Date: Nov 2024 (<em>TBA</em>)<br/>*/}
			▪ Artist : <a href={links.twitterGoop} target="_blank">@goopgoop_art</a><br/>
			▪ Deployment Date: <a target="_blank" href={getEtherscanTxnLink("0xf777736542b6a44ad392b58ab6d6cfa6cb2a5311ce39e98d7d4546837ba9083d")}>Nov 11, 2024</a><br/>
			▪ Provenance: <a target="_blank" href={getEtherscanTxnLink("0xf777736542b6a44ad392b58ab6d6cfa6cb2a5311ce39e98d7d4546837ba9083d")}>1st NFT on Unichain</a> (deployed during <a href="https://x.com/unichain/status/1856209898444587101" target="_blank">leak</a>)<br/>
			▪ Network: <a href={links.unichain} target="_blank">{chain.name}</a><br/>
			▪ Dex: <a href={links.dexscreener} target="_blank">Dexscreener</a>, <a href={links.uniswap} target="_blank">Uniswap</a><br/>
			▪ Supply: {data.totalSupply.toLocaleString()} {data.ticker}<br/>
			▪ Mint Date: Feb 2025 (Day 1 of Unichain)<br/>
			{/*▪ Mint Price (each): {data.pricePublic} Ξ (WL {data.priceWhitelist} Ξ)<br/>*/}
			▪ Token Standard: <a href={links.dn404} target="_blank">DN404</a> (hybrid erc721/erc20)<br/>
			▪ Storage: ✔️100% fully onchain<br/>
			▪ Contracts: <a href={links.contractFrontEnd} target="_blank">{abbreviateAddress(process.env.REACT_APP_CONTRACT_DN404,4)}</a> &amp; <a href={links.contractMirror} target="_blank">{abbreviateAddress(process.env.REACT_APP_CONTRACT_DNMIRROR,4)}</a> (erc721)<br/>
			▪ Num Variations: {data.numVariations.toLocaleString()} generative possibilities<br/>
			▪ Roadmap: zero roadmap, zero utility -- it's just art<br/>
          {/*▪ Marketplace: <a href={links.opensea} target="_blank">OpenSea</a>, <a href={links.elementStore} target="_blank">Element</a>, <a href={links.blur} target="_blank">Blur</a>*/}
		</p>

		<br/>
		<p>
		<em>tinyass unicorns is an experimental <a href={links.dn404} target="_blank">DN404</a> art project – with no expectation of future value. ape responsibly.</em>
		</p>
		</>
	)
}

function SectionDistribution() {

	return (
		<>
			<p style={{textAlign:"center"}}><strong>Token distribution of total supply:</strong></p>
			<div style={{margin:"0 auto", marginTop:"1em"}}>
				<Style.ResponsiveImage draggable="false" src={imgChart} />
			</div>

			<br/>
			<p>
				▪ <strong>{data.allocPublic}%</strong> of the total supply is for public/whitelist mint.<br/>
				▪ <strong>{data.allocLP}%</strong> will be LP'd into a <a href={links.uniswapLP} target="_blank">Uniswap pool</a> paired with ETH; LP tokens will be locked.<br/>
				▪ <strong>{data.allocDev}%</strong> treasury allocation for potential future integrations, MM, etc (no dumping).
			</p>
			
		</>
	)
}

function SectionTech() {

	return (
		<>
			<p>▪ <strong>DN404</strong></p>
			<p><a href={links.dn404} target="_blank">DN404</a> (DN stands for Dual Nature or Divisible NFTs) is a novel concept that merges NFTs and fungible tokens, allowing a collection to function as both ERC721 NFTs and ERC20 fungible tokens. To learn more, Scatter published a <a href="https://docs.scatter.art/blog/dn404/" target="_blank">great article on DN404</a>.</p>

			<br/>

			<p>▪ <strong>100% Fully Onchain Rendering Engine</strong></p>
			<p>This project exists entirely onchain, with no reliance on third-party storage solutions. We developed custom code that transforms <a href={links.twitterGoop} target="_blank">goopgoop_art's</a> handcrafted pixel-art layers directly into Solidity bytecode, ensuring the artwork is fully inscribed within the contract itself. As a result, all image layers are stored permanently onchain to generate {data.numVariations.toLocaleString()} possible variations. Read the contract <a href={links.contractFrontEnd} target="_blank">here</a>.</p>
		</>
	)
}

function SectionFolders() {
	const { isConnected , address } = useAccount();
	const { disconnect } = useDisconnect();
	const [folder, setFolder] = useState("collection");
	
	return (
		<>
			{isConnected && address && <p style={{fontSize:"0.75em", border:"1px dotted var(--text)", backgroundColor:"#e6ffb2", borderRadius:"5px", padding: "0.75em"}}><em>Tabs hidden during mint, <a onClick={disconnect}>disconnect wallet</a> to unhide.</em></p>}

			{ !(isConnected && address) &&
				<>
					<FolderHeader>
						<FolderTab onClick={()=>{setFolder("collection")}} active={folder=="collection"?"true":"false"}>info</FolderTab>
						<FolderTab onClick={()=>{setFolder("distribution")}} active={folder=="distribution"?"true":"false"}>distribution</FolderTab>
						<FolderTab onClick={()=>{setFolder("tech")}} active={folder=="tech"?"true":"false"}>tech</FolderTab>
					</FolderHeader>
					<hr/>

					<FolderContent>
						{folder=="collection" &&  <SectionCollection/>}
						{folder=="distribution" &&  <SectionDistribution/>}
						{folder=="tech" &&  <SectionTech/>}
					</FolderContent>
				</>
			}
      				
		</>
	)
}


function SectionHero() {

	const HIDDEN = data.hidden;

	return (
         <Style.Section>
      		<Style.HeroContainer >	

      			<Style.ColumnHeroImage>
      				<div style={{margin:"0 auto"}}>
      			    <Style.ResponsiveImage draggable="false" src={HIDDEN ? imgSingle : imgHero} />
					<p style={{textAlign:"center", display:"none"}}>( coming to <a href="https://www.unichain.org/" target="_blank">unichain</a> mainnet )</p>
      			    </div>
      			</Style.ColumnHeroImage>


				{!HIDDEN && (
      			<Style.ColumnHeroDesc>
      			   <h1>tinyass unicorns</h1>
               		<p>by dailofrog &nbsp;/&nbsp; <a href={links.twitter} target="_blank"><FaTwitter style={{position:"relative", top:"3px"}}/></a> <a href={links.linktree} target="_blank"><FaGlobe style={{position:"relative", top:"3px"}}/></a>{/* <a href={links.discord} target="_blank"><FaDiscord style={{position:"relative", top:"3px"}}/></a>*/}</p>
      			   <br/>
      				<p>100% fully onchain smol unicorns and <a target="_blank" href={getEtherscanTxnLink("0xf777736542b6a44ad392b58ab6d6cfa6cb2a5311ce39e98d7d4546837ba9083d")}>first NFTs</a> on <a href={links.unichain} target="_blank">Unichain</a>, built on <a href={links.dn404} target="_blank">DN404</a> (fractionalized NFTs). Magically coexists as an NFT and fungible memecoin. ✨</p>

							<br/>
					<Mint/>
							
					<br/>
					<SectionFolders/>
			  	
      			</Style.ColumnHeroDesc>
				)}
      		</Style.HeroContainer>
       
         </Style.Section>)
}

function FrontPage() {
  return (
      <>
		<Style.HiddenMobile>
        	<div style={{ borderTop: "20px solid var(--uniswap-pink-300)", position:"fixed", top:"0", width: "100%" }}/>
			<div style={{ borderBottom: "20px solid var(--uniswap-pink-200)", position:"fixed",  bottom:"0", width: "100%" }}/>
		</Style.HiddenMobile>

        <SectionHero/>
      </>
  );
}

export default FrontPage;
